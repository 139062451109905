<template>
  <v-row>
    <v-col v-for="rule in rules" :key="rule.key" :cols="rule?.cols ?? 4">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-checkbox
              v-if="rule.type == 'checkbox'"
              v-model="form[rule.key]"
              :label="rule.label"
              color="primary"
              required
              dense
            />
          </span>
        </template>
        <span>{{ rule.description }}</span>
      </v-tooltip>
      <v-text-field
        v-if="rule.type == 'number'"
        v-model="form[rule.key]"
        :label="rule.label"
        :hint="rule.description"
        required
        clearable
        type="number"
        dense
      ></v-text-field>
      <v-autocomplete
        v-if="rule.type == 'inputSelect'"
        v-model="form[rule.key]"
        :label="rule.label"
        :items="fields"
        :hint="rule.description"
        item-text="label"
        item-value="name"
        required
        dense
        clearable
        :multiple="rule.properties?.multiple ?? false"
      >
        <template v-slot:selection="{ item, index }">
          <span v-if="index < 3" small class="caption ml-1">
            {{ item.label + `, ` }}</span
          >
          <span v-if="index === 3" class="grey--text caption">
            (+{{ form[rule.key].length - 3 }} outros)
          </span>
        </template>

        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <v-row v-if="rule.type == 'minimumTimePiker'">
        <v-col cols="12"><span class="caption">Data e Hora mínima</span></v-col>

        <v-col cols="6">
          <v-autocomplete
            v-model="form['minimumDate']"
            label="Campo de data mínima"
            :items="getFieldsByComponentType('datePiker')"
            :hint="rule.description"
            item-text="label"
            item-value="name"
            required
            dense
            clearable
          />
        </v-col>

        <v-col cols="6">
          <v-autocomplete
            v-model="form['minimumTime']"
            label="Campo de hora mínima"
            :items="getFieldsByComponentType('timePiker')"
            :hint="rule.description"
            item-text="label"
            item-value="name"
            required
            dense
            clearable
          />
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="form['time']"
            v-mask="'##:##:##'"
            :label="rule.label"
            placeholder="(HH:MM:SS)"
            hint="Tempo em horas, minutos e segundos "
            required
            clearable
            dense
          />
        </v-col>
      </v-row>

      <v-row v-if="rule.key == 'defaultValue'">
        <v-col>
          <v-select
            v-if="rule.type == 'inputOptions'"
            v-model="form[rule.key]"
            :label="rule.label"
            :items="rule.options"
            :hint="rule.description"
            item-text="label"
            item-value="key"
            required
            dense
            clearable
          >
          </v-select>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Compoents from '../../../services/StructureReport/Structure'
import { LOAD_ALL_FIELDS } from '../../../vuex/modules/structure'
export default {
  name: 'Rules',

  props: {
    rules: {
      type: Array,
      required: true,
    },

    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      Compoents,

      form: {},

      fields: [],
    }
  },

  computed: {
    ...mapState('structure', ['data', 'loading', 'project']),
  },

  watch: {
    value: {
      handler() {
        this.form = this.value
      },
      immediate: true,
    },

    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
  },

  created() {
    this.getFields()
  },

  methods: {
    ...mapActions('structure', [LOAD_ALL_FIELDS]),

    async getFields() {
      this.fields = await this.LOAD_ALL_FIELDS()
    },

    getFieldsByComponentType(componentType) {
      return this.fields.filter(field => field.componentType === componentType)
    },
  },
}
</script>
