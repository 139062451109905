<template>
  <v-row>
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="700"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip v-if="structureItem.componentType" left>
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                icon
                color="yellow darken-1"
                v-bind="{ ...attrs, ...attrsTooltip }"
                @click="openUpdate"
                v-on="{ ...on, ...onTooltip }"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Editar componente</span>
          </v-tooltip>

          <v-tooltip
            v-if="structureItem.type == 'form' || structureItem.fields"
            left
          >
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                icon
                color="primary darken-1"
                v-bind="{ ...attrs, ...attrsTooltip }"
                v-on="{ ...on, ...onTooltip }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Novo componente</span>
          </v-tooltip>

          <v-tooltip v-if="structureItem?.fields1" left>
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                icon
                color="success darken-1"
                v-bind="{ ...attrs, ...attrsTooltip }"
                v-on="{ ...on, ...onTooltip }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Novo componente de segundo subcampos</span>
          </v-tooltip>
        </template>
        <template>
          <v-card>
            <v-toolbar color="primary" dark
              >Insira as informações do novo componente</v-toolbar
            >
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="component"
                    item-value="componentType"
                    :items="structureItems"
                    label="Componente"
                    filled
                    dense
                    @change="chosenComponent($event)"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.label }}
                    </template>

                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.description
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-form v-if="component" ref="form">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.title"
                      label="Título"
                      required
                      dense
                      filled
                      hint="Texto que aparecerá acima do campo"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.placeholder"
                      label="Placeholder"
                      required
                      dense
                      filled
                      hint="Texto que aparecerá no campo antes do usuário digitar"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="component.types" cols="6">
                    <v-select
                      v-model="form.type"
                      :items="component.types"
                      :item-text="item => item.label"
                      :item-value="item => item.value"
                      label="Tipos"
                      required
                      dense
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.label
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.description
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template></v-select
                    >
                  </v-col>
                  <v-col v-if="component.masks" cols="6">
                    <v-select
                      v-model="form.masks"
                      :items="component.masks"
                      :item-text="item => item.label"
                      :item-value="item => item.value"
                      label="Máscaras"
                      required
                      dense
                      clearable
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.label
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.description
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col v-if="component.rules">
                    <Rules v-model="rules" :rules="component.rules" />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn v-if="!form.name" color="green" text @click="create"
                >Criar</v-btn
              >
              <v-btn v-else color="green" text @click="update">Atualizar</v-btn>
              <v-btn color="red" text @click="dialog = false">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import StructureService from '../../../services/Structure'
import Structure from '../../../services/StructureReport/Structure'
import {
  CREATE_COMPONENT,
  CREATE_REPORT,
  DELETE_ITEM,
  UPDATE_COMPONENT,
} from '../../../vuex/modules/structure'
import Rules from './rules.vue'

export default {
  name: 'NewStructure',

  components: {
    Rules,
  },

  props: {
    structureItem: {
      type: Object,
      required: true,
    },

    projectStructure: {
      type: Object,
      default: () => ({}),
    },

    fields1: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    Structure,
    dialog: false,

    component: null,

    form: {
      title: '',
      placeholder: '',
      masks: '',
    },

    params: {},
    rules: [],

    actualField: [],
    reservedField: {},
  }),

  computed: {
    ...mapState('structure', [
      'data',
      'loading',
      'project',
      'historyOpenedIems',
    ]),

    items() {
      return this.Structure.map(item => item.label)
    },

    structureItems() {
      if (this.dialog && this.structureItem.componentType === 'someCheckbox') {
        return this.Structure.filter(item => item.componentType === 'checkbox')
      }

      return this.Structure
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.form = {
          title: '',
          postion: 1,
        }
      }
    },

    historyOpenedIems: {
      handler(value) {
        this.actualField = value[value.length - 1]
      },

      immediate: true,
    },
  },

  methods: {
    ...mapActions('structure', [
      CREATE_REPORT,
      CREATE_COMPONENT,
      UPDATE_COMPONENT,
      DELETE_ITEM,
    ]),

    chosenComponent(input) {
      this.component = this.Structure.find(item => item.componentType === input)
    },

    create() {
      const json = StructureService.generateJSONComponent({
        structure: this.component,
        form: this.form,
        mask: this.form.masks ?? 'default',
        params: this.params,
        rules: this.rules,
      })

      this.CREATE_COMPONENT({
        component: json,
        projectStructure: this.projectStructure,
        structureItem: this.structureItem,
      })

      this.dialog = false
    },

    update() {
      const json = StructureService.generateJSONComponent({
        structure: this.component,
        form: this.form,
        mask: this.form.masks ?? 'default',
        params: this.params,
        rules: this.rules,
      })

      this.UPDATE_COMPONENT({
        component: json,
        projectStructure: this.projectStructure,
        structureItem: this.structureItem,
      })

      this.dialog = false
    },

    openUpdate() {
      this.dialog = true

      this.component = this.Structure.find(
        item => item.componentType === this.structureItem.componentType,
      )

      this.form = {
        title: this.structureItem.label,
        placeholder: this.structureItem.placeholder,
        masks: this.structureItem.mask,
        name: this.structureItem.name,
        type: this.structureItem.type ?? 'default',
      }

      const rules = []

      this.structureItem.rules.forEach(rule => {
        rules[rule.key] = rule.value
      })

      this.rules = rules
    },
  },
}
</script>
